


import SECTION_LOTTERYGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_LOTTERYGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';



import "./style.css";


const LotteryPageDesktop = ()=>{
  
return (
  <div id="lotteryPageDesktop" class="page bg_global">
  
  

  
  < SECTION_LOTTERYGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `lottery_game_banner` } id={ `lottery_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_LOTTERYGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_lottery_game` } id={ `lotteryGameContainer` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={``} rowDesktop={ 3 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`LK`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`start`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  

  
</div>
)
}

export default LotteryPageDesktop;