



import ForgotPwdSection from '../sections/forgot_password/template_01';




import "./style.css";


const ForgotpwdPage = ()=>{
  
return (
  <div id="forgotpwdPage" class="page bg_global">
  
  

  
  <ForgotPwdSection desktopTitleToggle={ true }
    mobileTitleToggle={ true } />
  
  
  

  
</div>
)
}

export default ForgotpwdPage;