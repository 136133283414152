



import MyProfileMobile from '../sections/my_profile/template_01';




import "./style.css";


const ProfileMobile = ()=>{
  
return (
  <div id="profileMobile" class="page bg_global">
  
  

  
  <MyProfileMobile mobileTitleToggle={ true } />
  
  
  

  
</div>
)
}

export default ProfileMobile;