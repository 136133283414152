



import RegisterMobile from '../sections/register/template_01';




import "./style.css";


const RegisterPage = ()=>{
  
return (
  <div id="registerPage" class="page bg_global">
  
  

  
  <RegisterMobile desktopTitleToggle={ true }
    mobileTitleToggle={ true } birthdayReminder={ false } />
  
  
  

  
</div>
)
}

export default RegisterPage;