


import SECTION_OTHERSGAMEBANNERSECTION from
'../dynamic_section/dynamic_2/template_01';





import SECTION_OTHERSGAMECONTAINERSECTION from
'../dynamic_section/dynamic_1/template_01';



import "./style.css";


const OthersPageDesktop = ()=>{
  
return (
  <div id="othersPageDesktop" class="page bg_global">
  
  

  
  < SECTION_OTHERSGAMEBANNERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `others_game_banner` } id={ `others_banner` } supportLanguage={
    true } titleToggle={ false } titleIconToggle={
    true } linkTo={ `` } addContainer={ false } numberOfImage={ 1 } titlePosition={ `start` }/>
  
  
  
  

  
  < SECTION_OTHERSGAMECONTAINERSECTION desktopToggle={ true }
    mobileToggle={ false } label={ `Section_others` } id={ `othersContainer` }
    imageSize={`1x2`} contentPosition={`bottom`}
    actionPosition={``} rowDesktop={ 3 }
    rowMobile={ 3 } numberOfRow={ -1 }
    dataType={`game provider`} noPadding={ false } filter={`OT`}
    titleToggle={ false } titleIconToggle={ true }
    cardPosition={`start`} pagination={ true }
    autoplay={ true } navigation={ true }
    dynamicQuery={`` } filterTabToggle={ false }
    folderImage={``} noLink={ false }/>
  
  
  

  
</div>
)
}

export default OthersPageDesktop;